import "./map.scss";

import React from "react";

const ActionMap: React.FC = () => {
  return (
    <div className={"m-map"}>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 683 716"
        xmlSpace="preserve"
      >
        <path
          id="Buenos_Aires"
          fill="#B51231"
          d="M379.6,656.9c0,0,26.2-10.5,27.8-25.4c1.6-14.9-12.7-13.8-12.7-13.8l2.2-12.2l4.3-2.2
	l2.2-14.3l8.4-11.6l3-19.2l-11.4-10.5l1.4-15.1l-2.7-4.9l-1.9-10.3c0,0,16.5-4.1,19.7,9.7c3.2,13.8-7,16.7,3,17.6
	c10,0.8,3.2-10.8,3.2-10.8s169.3-15.1,196.6-43.5l5.4-16.2c0,0,13-21.1,24.3-25.7s19.2-28.6,19.2-28.6V385c0,0-27-3.2-29.4-18.1
	c-2.4-14.9,11.1-27,12.2-37c1.1-10-33.8-44.6-48.1-42.7c-14.3,1.9-24.6,0.8-25.4-5.1c-0.8-5.6,14.7-27.3,23-31.6
	c0.4-0.2,0.9-0.4,1.3-0.5c7.8-2.4-6.2-8.6-6.2-8.6s2.7,4.6-11.3,0s-71-37.5-76.4-45.1l-3,2.4c0,0-4.3,15.4-10.3,16.2
	c-5.9,0.8-9.2-7-17.6-4.3c-8.4,2.7-40.8,47-40.8,47l-83.7,0.8l1.4,373c0,0,15.9,7.8,18.6,17.8S379.6,656.9,379.6,656.9z"
        />
        <path
          fill="#F0D0D6"
          stroke="#FFFFFF"
          strokeWidth={5}
          strokeMiterlimit={10}
          d="M353,555c-37.3-44.3-104.9-41.4-131.9-39.3c-27,2.2-6.3-6.2-20.1-10.7c-13.8-4.5-22.9-9.1-24.5-16.4
	c0,0,3-4.9-10.9,1c-14,5.9-13.6-6.9-13.6-6.9s-16.8-3.2-12.2-10.3c4.7-7.1,11.7-16.4,2.8-19.2c-5.9-1.9-11.9-3.1-15.2-3.8
	c-1.6-0.3,4.1-2.3,3.7-3.9l-2.8-5.3l-5.1-78.4l148.3-9.6l-1.6-62.1h80.5L353,555z"
        />
        <path
          fill="#F0D0D6"
          stroke="#FFFFFF"
          strokeWidth={5}
          strokeMiterlimit={10}
          d="M273.5,293.7c0,0-3.2-87.2-4.3-95.9c-1.1-8.6-0.4-6.1,5-14.7c5.4-8.6,5-45.4,5-45.4h-14.4c0,0,4.9-9.7-12.7-20
	s-36.5-3.5-44.7-1.6c-8.2,1.9-27.5-4.1-41.9-5.1c-14.3-1.1-12.7,3.2-12.8,7.6c-0.1,4.3,0,77.7,0,77.7v160.4l121.4-4.1L273.5,293.7z"
        />
        <path
          id="Mendoza"
          fill="#B51231"
          d="M19,376.7c0,0-2-39.3-8.5-46.6s6.3-8.1,7.3-7.7c1,0.4,1.2-30.6,1.2-30.6s10.3-15.8,10.1-18
	c-0.2-2.2-1-8.3-1-8.3l10.7-2.4l-5.5-15.8c0,0,1.4-35.9,2.6-38.5c1.2-2.6-13.8,1.4-16.2-6.9c-2.4-8.3,3.9-17,3.9-17l-9.4-8.7
	c0,0,0.2-19.4-2.6-22.5c-2.8-3,7.3-5.5,18.2-5.5s26.1-25.7,33-22.1s14,18.6,21.5,18.2c7.5-0.4,35.2-18.6,35.2-18.6s1.8,4.6,8.5,8.9
	c6.7,4.3,24.1,1.8,24.1,1.8s-2.8,4.1,1.6,11.3c4.5,7.3,11.3,17.4,11.5,27.8c0.2,10.3,3.8,44,10.7,50.4c6.9,6.5,12.2,23.9,12.6,35.4
	s13.6,27.1,14.2,40.1c0.6,13-2.6,55.3-2.6,55.3l-78.6,1.8l5.5,95.4c0,0-17.4-10.3-29-8.5s-9.5-12.8-9.5-12.8L57.5,432
	c0,0-9.3-23.5-21.9-32.4C23,390.6,19,376.7,19,376.7z"
        />
        <path
          fill="#F0D0D6"
          stroke="#FFFFFF"
          strokeWidth={5}
          strokeMiterlimit={10}
          d="M279.1,137.7h-14.4c0,0,4.9-9.7-12.7-20c-17.6-10.3-36.5-3.5-44.7-1.6c-8.2,1.9-27.5-4.1-41.9-5.1
	c-14.3-1.1-12.7,3.2-12.8,7.6c-0.1,1.6-0.1,13.1-0.1,26.7c0.3,0.8,0.8,1.7,1.3,2.6c4.5,7.3,11.3,17.4,11.5,27.8
	c0.2,10.3,3.8,44,10.7,50.4c6.9,6.5,12.2,23.9,12.6,35.4c0.4,11.5,13.6,27.1,14.2,40.1c0.5,11.4-1.9,45.3-2.5,53.5l73.6-2.5
	l-0.5-58.8c0,0-3.2-87.2-4.3-95.9c-1.1-8.6-0.4-6.1,5-14.7C279.5,174.4,279.1,137.7,279.1,137.7z"
        />
      </svg>
    </div>
  );
};

export default ActionMap;
