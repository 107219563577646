import "./ctas-rrhh.scss";

import * as React from "react";

import { TypeForm } from "../Forms/domain/Forms";
import { FormCv } from "../Forms/Views/FormCv";
import withForm from "../Forms/withForm";
import { useModal } from "../Modals/useModal";
import { withModal } from "../Modals/withModal";

const CtasRRHH: React.FC = () => {
  const FormCvWithForm = withForm(FormCv, TypeForm.CV);

  const [has_modal, toggleModal] = useModal();
  const FormOpen = withModal(
    FormCvWithForm,
    {},
    {
      has_modal,
      toggleModal,
    }
  );

  return (
    <>
      <FormOpen />
      <div className={"m-ctas-rrhh"}>
        <div className="c-ctas-rrhh">
          <section className={"m-ctas-rrhh__card"}>
            <p className={"m-ctas-rrhh__title"}>RECIBOS</p>
            <p className={"m-ctas-rrhh__text"}>
              Descargá tu recibo de sueldo pulsando el botón
            </p>
            <div>
              <a
                className={"m-button"}
                href="https://cyr.jornalya.boxcustodia.com/"
                target={"_blank"}
                rel={"noopener nofollow noreferrer"}
              >
                Descargar
              </a>
            </div>
          </section>
          <section className={"m-ctas-rrhh__card"}>
            <p className={"m-ctas-rrhh__title"}>TRABAJÁ CON NOSOTROS</p>
            <p className={"m-ctas-rrhh__text"}>
              Si querés ser parte de nuestro equipo, dejanos tu currículum.
            </p>
            <a
              className={"m-button"}
              href={`https://tally.so/r/nrBqxv`}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Adjuntar el cv
            </a>
          </section>
          <section className={"m-ctas-rrhh__card"}>
            <p className={"m-ctas-rrhh__title"}>CAPACITACIONES</p>
            <p className={"m-ctas-rrhh__text"}>
              Hacé click en el botón Ingresár para acceder a nuestras
              capacitaciones
            </p>
            <p>
              <a
                className={"m-ctas-rrhh__link"}
                href="https://www.cyrseguridad.com.ar/descargas/Plan-capacitacion-2023.pdf"
                target={"_blank"}
                rel={"noopener nofollow noreferrer"}
              >
                Ver plan de capacitaciones
              </a>
            </p>
            <div>
              <a
                className={"m-button"}
                href="http://capacitaciones.retr.com.ar/auth/login/"
                target={"_blank"}
                rel={"noopener nofollow noreferrer"}
              >
                Ingresar
              </a>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default CtasRRHH;
