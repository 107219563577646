import { FormRepository } from "../domain/FormRepository";

export class FormRepositoryGatsbyFunctions implements FormRepository {
  send(formData: FormData): Promise<Response> {
    const body = {
      name: formData.get("name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
    };
    return fetch(`/api/send-form`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });
  }
}
