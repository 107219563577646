import { FormRepository } from "../domain/FormRepository";

export class CVRepositoryGatsbyFunctions implements FormRepository {
  send(formData: FormData): Promise<Response> {
    return fetch(`/api/send-cv`, {
      method: "POST",
      body: formData,
    });
  }
}
