import { FormRepository } from "../domain/FormRepository";
import { TypeForm } from "../domain/Forms";
import { CVRepositoryGatsbyFunctions } from "./CVRepositoryGatsbyFunctions";
import { FormRepositoryGatsbyFunctions } from "./FormRepositoryGatsbyFunctions";

export const FormRepositoryFactory = (typeForm: TypeForm): FormRepository => {
  switch (typeForm) {
    case TypeForm.INFO:
      return new FormRepositoryGatsbyFunctions();
    case TypeForm.CV:
      return new CVRepositoryGatsbyFunctions();
  }
};
