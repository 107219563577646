import * as React from "react";
// Remember that all const are suffix _svg

const update_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.84"
    height="17.833"
    viewBox="0 0 17.84 17.833"
  >
    <g
      id="upload-svgrepo-com_1_"
      data-name="upload-svgrepo-com (1)"
      transform="translate(-11 -10.986)"
    >
      <g id="Grupo_171" data-name="Grupo 171" transform="translate(11 10.986)">
        <path
          id="Trazado_161"
          data-name="Trazado 161"
          d="M119.623,16.83l2.982-3.208v9.659a.743.743,0,1,0,1.485,0V13.622l2.982,3.208a.745.745,0,0,0,1.092-1.012l-4.271-4.595a.746.746,0,0,0-1.089,0l-4.271,4.595a.743.743,0,1,0,1.089,1.012Z"
          transform="translate(-114.427 -10.986)"
          fill="#5cb4e1"
        />
        <path
          id="Trazado_162"
          data-name="Trazado 162"
          d="M28.1,341.2a.741.741,0,0,0-.743.743v3.583H12.485v-3.583a.743.743,0,0,0-1.485,0v4.325a.741.741,0,0,0,.743.743H28.1a.741.741,0,0,0,.743-.743v-4.325A.743.743,0,0,0,28.1,341.2Z"
          transform="translate(-11 -329.178)"
          fill="#5cb4e1"
        />
      </g>
    </g>
  </svg>
);

const waveLoading_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="128.992"
    className={"wave_sending"}
    height="130.952"
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectángulo 119"
          fill="none"
          stroke="#59b1de"
          d="M0 0h128.992v130.952H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Grupo 111"
      clipPath="url(#a)"
      fill="none"
      stroke="#59B1DE"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        data-name="Trazado 111"
        d="M7.549 20.029a15.4 15.4 0 0 0-3.323 10.956c.872 7.958 8.736 13.858 9.443 21.833C14.671 64.137 1.147 72.196.388 83.534c-.536 8 5.807 15.179 13.221 18.242 8.351 3.451 18.525.448 26.065 4.981 8.822 5.3 8.494 20.067 18.423 23.372 5.678 1.89 11.912-2.229 14.64-7.556s2.906-11.536 3.384-17.5c.619-7.742 1.018-14.236 8.708-18.405 5.28-2.863 11.414-3.626 17.093-5.582a42.779 42.779 0 0 0 23.721-20.407c3.923-7.508 4.751-18.279-2.208-23.11-4.164-2.89-9.766-2.6-14.669-1.319-9.343 2.449-19.615 6.931-29.018 1.544-8.028-4.6-8.351-15.23-12.633-22.738-3.7-6.48-9.473-12.127-16.7-13.994-10.23-2.647-20.702 2.626-29.772 8.056-4.778 2.861-9.854 6.501-13.094 10.911Z"
        strokeDasharray="0 2"
      />
      <path
        data-name="Trazado 112"
        d="M9.971 21.172A14.956 14.956 0 0 0 6.78 31.544c.511 5.374 4.3 9.806 6.689 14.652a17.507 17.507 0 0 1 1.892 6.106C16.367 63.008 3.888 70.727 3.92 81.346c.011 4.029 1.658 7.516 3.883 10.976a21.538 21.538 0 0 0 8.738 7.878c7.355 3.553 16.6 1.407 23.818 4.658q.605.272 1.19.6c8.624 4.788 8.894 17.811 18.509 21 5.469 1.815 11.375-1.692 14.117-6.344s3.246-10.152 3.965-15.692c.853-6.519 1.281-12.4 6.438-16.559a17.141 17.141 0 0 1 1.753-1.241c4.693-2.916 10.1-3.9 15.21-6.009a46.371 46.371 0 0 0 22.236-20.2 27.445 27.445 0 0 0 3.688-12.341c.085-4.683-1.742-9.186-5.669-11.612-4.147-2.563-9.441-2.24-14.137-.887-7.684 2.216-15.954 5.907-23.964 3.693a17.833 17.833 0 0 1-3.925-1.607c-7.763-4.312-8.45-14.41-12.746-21.535-3.665-6.072-9.232-11.327-16.135-13.06-9.8-2.457-19.823 2.447-28.467 7.635-4.567 2.742-9.371 6.237-12.451 10.473Z"
        strokeWidth="1.001"
        strokeDasharray="0 2.001"
      />
      <path
        data-name="Trazado 113"
        d="M12.392 22.317a14.537 14.537 0 0 0-3.059 9.786c.4 5.068 3.8 9.3 5.97 13.905a17.589 17.589 0 0 1 1.75 5.778c1.01 10.092-10.333 17.513-9.6 27.372.3 4.088 2.142 7.237 4.151 10.987a19.912 19.912 0 0 0 7.868 8.471c6.806 3.8 15.756 2.068 22.791 4.993q.589.245 1.161.534c8.426 4.27 9.293 15.554 18.6 18.63 5.261 1.74 10.839-1.154 13.594-5.131s3.587-8.754 4.547-13.863c1.145-6.017 1.621-11.781 6.138-15.942a16.312 16.312 0 0 1 1.535-1.254c4.107-2.969 8.793-4.17 13.328-6.438a51.936 51.936 0 0 0 20.75-19.988c2.25-3.878 4.176-8.271 4.383-12.785.2-4.877-1.93-9.7-6.138-12.012-4.11-2.253-9.116-1.877-13.606-.454-7.348 2.331-15.183 6.112-22.95 4.115a17 17 0 0 1-3.813-1.488c-7.5-4.023-8.546-13.591-12.859-20.33-3.632-5.672-8.991-10.536-15.576-12.135-9.365-2.268-18.939 2.267-27.155 7.213-4.357 2.623-8.89 5.973-11.81 10.036Z"
        strokeWidth="1.001"
        strokeDasharray="0 2.003"
      />
      <path
        data-name="Trazado 114"
        d="M14.816 23.462a14.143 14.143 0 0 0-2.926 9.2c.289 4.761 3.291 8.8 5.251 13.16a17.877 17.877 0 0 1 1.607 5.449c1.014 9.478-9.093 16.627-7.764 25.7.61 4.169 2.632 6.95 4.418 11 1.619 3.677 3.447 6.794 7 9.064 6.263 4 14.907 2.728 21.762 5.329q.575.218 1.133.471c8.228 3.753 9.693 13.3 18.681 16.257a12.462 12.462 0 0 0 13.071-3.919c2.768-3.3 3.927-7.362 5.13-12.043 1.436-5.516 1.961-11.172 5.838-15.323a16.1 16.1 0 0 1 1.317-1.268c3.528-3.012 7.483-4.445 11.445-6.865a61.237 61.237 0 0 0 19.266-19.779c2.453-4.035 4.763-8.48 5.079-13.23.337-5.071-2.1-10.233-6.607-12.411-4.055-1.962-8.79-1.513-13.074-.021-7.013 2.445-14.413 6.317-21.938 4.535a16.086 16.086 0 0 1-3.693-1.366c-7.232-3.734-8.639-12.771-12.972-19.125-3.594-5.269-8.749-9.74-15.015-11.2-8.923-2.08-18.055 2.086-25.844 6.789-4.145 2.501-8.406 5.707-11.165 9.596Z"
        strokeWidth="1.002"
        strokeDasharray="0 2.004"
      />
      <path
        data-name="Trazado 115"
        d="M17.238 24.606a13.839 13.839 0 0 0-2.793 8.615c.178 4.456 2.785 8.291 4.532 12.415a18.449 18.449 0 0 1 1.464 5.12c1.018 8.865-7.751 15.756-5.925 24.028.941 4.265 3.132 6.655 4.684 11.017 1.361 3.824 2.632 7.111 6.132 9.656 5.733 4.166 14.058 3.388 20.734 5.664q.56.19 1.1.409c8.03 3.236 10.092 11.039 18.768 13.886a12.862 12.862 0 0 0 12.548-2.706c2.78-2.628 4.258-5.974 5.712-10.224 1.717-5.02 2.3-10.567 5.537-14.7a16.645 16.645 0 0 1 1.1-1.282c2.945-3.064 6.175-4.721 9.562-7.292a78.992 78.992 0 0 0 17.781-19.57c2.663-4.189 5.34-8.69 5.774-13.675.457-5.264-2.263-10.773-7.076-12.81-3.989-1.688-8.465-1.15-12.543.411-6.671 2.553-13.635 6.517-20.913 4.953a15.344 15.344 0 0 1-3.577-1.245c-6.972-3.448-8.733-11.955-13.089-17.924-3.554-4.867-8.509-8.944-14.456-10.275-8.486-1.891-17.17 1.906-24.532 6.367-3.936 2.387-7.918 5.453-10.524 9.162Z"
        strokeWidth="1.003"
        strokeDasharray="0 2.005"
      />
      <path
        data-name="Trazado 116"
        d="M19.66 25.75a13.551 13.551 0 0 0-2.661 8.031c.067 4.149 2.28 7.786 3.813 11.668a19.414 19.414 0 0 1 1.322 4.791c1.022 8.253-6.316 14.883-4.086 22.356 1.3 4.369 3.641 6.356 4.951 11.032 1.112 3.967 1.824 7.413 5.264 10.248 5.219 4.3 13.209 4.049 19.706 6q.544.163 1.077.347c7.831 2.719 10.49 8.782 18.853 11.514a13.937 13.937 0 0 0 12.026-1.494c2.794-1.953 4.6-4.584 6.295-8.4 2.007-4.52 2.64-9.969 5.237-14.087.277-.439.563-.874.882-1.295 2.361-3.116 4.867-5 7.679-7.72a123.472 123.472 0 0 0 16.294-19.36c2.881-4.34 5.917-8.9 6.47-14.12.578-5.459-2.413-11.329-7.545-13.209-3.911-1.434-8.139-.787-12.011.844-6.341 2.674-12.871 6.728-19.912 5.377a14.493 14.493 0 0 1-3.461-1.124c-6.7-3.158-8.813-11.137-13.2-16.718-3.51-4.467-8.267-8.149-13.9-9.345-8.048-1.7-16.286 1.725-23.221 5.944a32.583 32.583 0 0 0-9.872 8.72Z"
        strokeWidth="1.003"
        strokeDasharray="0 2.007"
      />
      <path
        data-name="Trazado 117"
        d="M22.083 26.895a13.35 13.35 0 0 0-2.528 7.446c-.044 3.844 1.774 7.281 3.094 10.923a21.233 21.233 0 0 1 1.179 4.462c1.025 7.639-4.8 14-2.248 20.683 1.7 4.469 4.161 6.055 5.219 11.047.87 4.108 1.025 7.7 4.4 10.841 4.725 4.406 12.36 4.71 18.678 6.335.353.091.7.185 1.049.285 7.634 2.2 10.89 6.525 18.94 9.143a16.363 16.363 0 0 0 11.5-.281 14.556 14.556 0 0 0 6.877-6.585c2.3-4.021 2.974-9.376 4.938-13.469.212-.443.425-.882.664-1.308a60.094 60.094 0 0 1 5.8-8.148c4.1-5.238 9.993-12.191 14.81-19.151 3.1-4.487 6.493-9.109 7.166-14.565.7-5.653-2.556-11.906-8.013-13.609-3.827-1.195-7.815-.423-11.479 1.276-6.006 2.789-12.1 6.933-18.9 5.8a13.737 13.737 0 0 1-3.345-1c-6.437-2.869-8.894-10.323-13.31-15.513-3.462-4.07-8.026-7.353-13.336-8.415-7.611-1.514-15.4 1.545-21.909 5.521a30.15 30.15 0 0 0-9.246 8.282Z"
        strokeWidth="1.004"
        strokeDasharray="0 2.008"
      />
      <path
        data-name="Trazado 118"
        d="M24.505 28.039a13.3 13.3 0 0 0-2.4 6.861c-.147 3.538 1.27 6.775 2.375 10.178a24.792 24.792 0 0 1 1.036 4.133c1.03 7.025-3.206 13.085-.408 19.011 2.148 4.551 4.691 5.753 5.486 11.061.637 4.249.245 7.966 3.528 11.434 4.253 4.491 11.511 5.37 17.649 6.67.344.073.684.147 1.021.223 7.436 1.684 11.289 4.267 19.026 6.77a22.042 22.042 0 0 0 10.98.931 11.6 11.6 0 0 0 7.459-4.766c2.591-3.52 3.3-8.788 4.637-12.851.147-.447.287-.889.447-1.321a56.227 56.227 0 0 1 3.913-8.576c3.094-5.556 8.584-12.368 13.325-18.942 3.337-4.626 7.068-9.318 7.861-15.009.815-5.848-2.692-12.506-8.482-14.008-3.74-.97-7.489-.06-10.948 1.708-5.671 2.9-11.329 7.138-17.885 6.221a13.017 13.017 0 0 1-3.229-.883c-6.171-2.58-8.97-9.513-13.423-14.309A23.661 23.661 0 0 0 53.7 15.09c-7.174-1.326-14.518 1.365-20.6 5.1a27.732 27.732 0 0 0-8.595 7.849Z"
        strokeWidth="1.005"
        strokeDasharray="0 2.009"
      />
      <path
        data-name="Trazado 119"
        d="M26.927 29.186a13.407 13.407 0 0 0-2.264 6.276c-.259 3.232.765 6.27 1.656 9.431a32.99 32.99 0 0 1 .893 3.8c1.034 6.413-1.564 12.137 1.431 17.34 2.647 4.6 5.229 5.452 5.753 11.076.41 4.391-.515 8.219 2.66 12.026 3.8 4.56 10.662 6.031 16.621 7.006l.993.16a111.72 111.72 0 0 1 19.111 4.4 39.406 39.406 0 0 0 10.457 2.144c2.832.07 5.621-.413 8.04-2.946 2.885-3.02 3.617-8.2 4.337-12.233.08-.451.149-.9.229-1.335a54.416 54.416 0 0 1 2.031-9c2.075-5.88 7.183-12.538 11.839-18.733 3.577-4.76 7.633-9.53 8.558-15.453.923-6.044-2.826-13.133-8.952-14.408-3.651-.76-7.165.3-10.416 2.141-5.335 3.018-10.558 7.343-16.872 6.641a12.341 12.341 0 0 1-3.113-.762c-5.907-2.291-9.042-8.706-13.536-13.1a23.275 23.275 0 0 0-12.216-6.556c-6.736-1.137-13.633 1.185-19.288 4.676a25.333 25.333 0 0 0-7.952 7.409Z"
        strokeWidth="1.005"
        strokeDasharray="0 2.011"
      />
      <path
        data-name="Trazado 120"
        d="M29.349 30.328a13.79 13.79 0 0 0-2.131 5.69c-.376 2.926.26 5.765.937 8.687.268 1.141.54 2.3.751 3.476 1.037 5.8.11 11.14 3.269 15.667 3.205 4.592 5.775 5.154 6.02 11.091.188 4.535-1.249 8.459 1.792 12.618 3.378 4.621 9.814 6.691 15.593 7.341q.485.054.964.1c7.039.65 12.087-.246 19.2 2.028 3.8 1.216 7.088 2.611 9.934 3.356s5.964.979 8.623-1.126c3.179-2.518 3.922-7.618 4.036-11.614.013-.456.01-.905.012-1.349.012-3.34-.4-6.167.147-9.431 1.042-6.2 5.791-12.7 10.354-18.524 3.828-4.884 8.208-9.74 9.252-15.9 1.043-6.238-2.957-13.789-9.42-14.807-3.564-.561-6.839.667-9.884 2.573-5 3.133-9.787 7.548-15.86 7.063a11.726 11.726 0 0 1-3-.641c-5.641-2-9.106-7.909-13.648-11.9A23.027 23.027 0 0 0 54.633 19.1c-6.3-.949-12.748 1-17.976 4.253a22.94 22.94 0 0 0-7.308 6.975Z"
        strokeWidth="1.006"
        strokeDasharray="0 2.012"
      />
      <path
        data-name="Trazado 121"
        d="M31.772 31.472a14.769 14.769 0 0 0-2 5.106 22.32 22.32 0 0 0 .22 7.941c.182 1.042.4 2.09.608 3.146 1.041 5.185 1.793 10.085 5.108 13.995 3.827 4.509 6.327 4.859 6.288 11.106-.031 4.682-1.952 8.689.923 13.21 2.978 4.682 8.965 7.352 14.565 7.677q.47.028.936.036c6.842.133 12.487-2.5 19.284-.344 3.589 1.141 6.552 3.149 9.411 4.569s6.31 2.376 9.205.693c3.477-2.009 4.22-7.025 3.737-11a29.95 29.95 0 0 0-.206-1.362c-.581-3.4-1.733-6.457-1.735-9.859 0-6.518 4.4-12.854 8.869-18.314 4.088-5 8.784-9.95 9.947-16.343 1.163-6.433-3.092-14.478-9.888-15.207-3.481-.373-6.514 1.03-9.353 3.006-4.663 3.247-9.016 7.753-14.846 7.483a11.146 11.146 0 0 1-2.881-.52c-5.376-1.715-9.166-7.119-13.762-10.7a22.957 22.957 0 0 0-11.1-4.7 25.665 25.665 0 0 0-16.664 3.831 20.5 20.5 0 0 0-6.666 6.55Z"
        strokeWidth="1.007"
        strokeDasharray="0 2.013"
      />
      <path
        data-name="Trazado 122"
        d="M31.826 44.332a24.875 24.875 0 0 0 7.414 15.14c4.494 4.327 6.887 4.566 6.555 11.121-.245 4.832-2.623 8.913.055 13.8a15.969 15.969 0 0 0 13.536 8.012c6.985 0 13.5-4.881 20.278-2.743 3.38 1.066 6.016 3.687 8.888 5.782s6.65 3.768 9.787 2.513c3.769-1.508 4.511-6.422 3.436-10.378s-3.421-7.647-4.041-11.663c-1.055-6.821 3.022-13 7.384-18.1s9.36-10.16 10.643-16.787-3.234-15.2-10.358-15.606c-9-.512-13.668 11.429-22.654 11.343-6.462-.062-11.176-6.194-16.639-9.892-10.4-7.038-26.024-4.226-31.916 5.744a19.079 19.079 0 0 0-2.368 11.714Z"
        strokeWidth="1.007"
        strokeDasharray="0 2.015"
      />
    </g>
  </svg>
);

const check_svg = (
  <svg xmlns="http://www.w3.org/2000/svg" width="13.867" height="12.133">
    <path
      d="M10.77.663a1.733 1.733 0 0 1 2.85 1.962l-.12.176-6.937 8.67a1.734 1.734 0 0 1-2.424.3l-.164-.145L.508 8.159a1.733 1.733 0 0 1 2.288-2.6l.163.144 2.085 2.085Z"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);

const fail_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.536"
    height="20.536"
    viewBox="0 0 20.536 20.536"
  >
    <line
      id="Line_38"
      data-name="Line 38"
      x2="17"
      y2="17"
      transform="translate(1.768 1.768)"
      fill="none"
      stroke="#ac4242"
      strokeWidth="5"
    />
    <line
      id="Line_39"
      data-name="Line 39"
      y1="17"
      x2="17"
      transform="translate(1.768 1.768)"
      fill="none"
      stroke="#ac4242"
      strokeWidth="5"
    />
  </svg>
);

export { check_svg, fail_svg, update_svg, waveLoading_svg };
