import { SEO } from "components/common/SEO";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import ActionRatio from "../components/actionRatio/ActionRatio";
import Layout from "../components/common/layout/layout";
import CtasRRHH from "../components/CtasRRHH/CtasRRHH";
import OurPolitics from "../components/File/OurPolitics";

const RRHHPage = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      site {
        siteMetadata {
          siteUrl
          title
          description
          author
          foundingDate
          socialLinks {
            facebook
            twitter
            linkedin
          }
          address {
            streetAddress
          }
          phone
          phones {
            phone_buenosaires {
              city
              number
            }
            phone_mendoza {
              city
              number
            }
          }
          email
        }
      }
    }
  `);

  return (
    <Layout>
      <div className={"o-hero-page"}>
        <div className="container flex-center--column">
          <div className="hero-page__title">
            <h1 className={"a-title--hero-page"}>RECURSOS HUMANOS</h1>
          </div>
          <div className="hero-page__desc">
            <p>Seleccione una opción</p>
          </div>
          <CtasRRHH />
        </div>
      </div>
      <ActionRatio />
    </Layout>
  );
};

export const Head = () => (
  <SEO
    title="RRHH C&R Seguridad"
    description="Puede contactarnos a través de las diferentes formas de contacto que tenemos disponibles."
    robots="noindex, nofollow"
    canonical={"rrhh/"}
  />
);

export default RRHHPage;
