import * as React from "react";

import { PropsForm } from "./components/Info/InfoForm";
import { TypeForm } from "./domain/Forms";
import { useForm } from "./useForm";

function withForm(
  Form: React.FC<PropsForm>,
  typeForm: TypeForm,
  fromLanding = ""
): React.ComponentClass {
  const { handleSubmit, stateForm, infoRequest } = useForm(typeForm);
  return class x extends React.Component {
    render() {
      return (
        <Form {...{ handleSubmit, stateForm, infoRequest, fromLanding }} />
      );
    }
  };
}

export default withForm;
