import "./form-cv.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

import Submit, { SubmitType } from "../components/Submit/Submit";
import { update_svg } from "../elements_form";
import { StateForm } from "../useForm";

export type PropsForm = PropsWithChildren<{
  handleSubmit(event: React.FormEvent<HTMLFormElement>): void;
  stateForm: StateForm;
  infoRequest: string;
}>;

export const FormCv: React.FC<PropsForm> = (props: PropsForm) => {
  const submitJoinUs: SubmitType = {
    stateForm: props.stateForm,
    textButtonSubmit: "Enviar",
    textButtonSubmitting: "Cargando cv ...",
    textInfo: props.infoRequest,
  };
  return (
    <form
      encType="multipart/form-data"
      name="trabaja-con-nosotros"
      method="post"
      onSubmit={(event) => {
        props.handleSubmit(event);
      }}
      className="m-form-cv"
    >
      <div className="m-form-cv__inputs">
        <div className="m-input-file">
          <label
            aria-label={"Subir curriculum"}
            className="a-input-file__label"
            htmlFor={"cv"}
          >
            {update_svg}
          </label>
          <input
            className={"a-input-file"}
            accept="application/pdf"
            size={2000000}
            type="file"
            name="cv"
            placeholder="Subir curriculum"
            required
          />
        </div>
        <div className={"m-form-cv__policy"}>
          <input
            type="checkbox"
            className={"a-input-check"}
            name="politica-cv"
            id="politica-cv"
            value="acepta-politica"
            required
          />
          <label htmlFor="politica-cv" className={"m-form-cv__label"}>
            He leído y acepto la{" "}
            <a
              href="/legal/politica-de-privacidad/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              política de privacidad
            </a>{" "}
          </label>
        </div>
      </div>
      <Submit submit={submitJoinUs} />
    </form>
  );
};
