import React, { useState } from "react";

import { TypeForm } from "./domain/Forms";
import { FormRepositoryFactory } from "./infrastructure/FormRepositoryFactory";

export const enum StateForm {
  REST,
  SUBMITTING,
  SUCCESS,
  FAIL,
}

export interface PropsUseForm {
  handleSubmit(event: React.FormEvent<HTMLFormElement>): void;
  stateForm: StateForm;
  infoRequest: string;
}

export const useForm = (typeForm: TypeForm): PropsUseForm => {
  const [stateForm, setStateForm] = useState<StateForm>(StateForm.REST);
  const [infoRequest, setInfoRequest] = useState<string>("");

  const formRepository = FormRepositoryFactory(typeForm);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStateForm(StateForm.SUBMITTING);
    const data: FormData = new FormData(e.target as HTMLFormElement);
    formRepository
      .send(data)
      .then((response) => {
        if (response.ok) {
          setStateForm(StateForm.SUCCESS);
        } else {
          setStateForm(StateForm.FAIL);
        }
        setInfoRequest(response.statusText);
      })
      .catch((e) => {
        setStateForm(StateForm.FAIL);
        setInfoRequest(e.toString);
      });
  };
  return {
    handleSubmit,
    stateForm,
    infoRequest,
  };
};
