import "./our-politics.scss";

import React from "react";

import File from "./File/File";
import { drugs_svg, health_svg, Quality_svg } from "./File/file_elements";

const OurPolitics: React.FC = () => {
  const features: File[] = [
    {
      name: "Política de seguridad y salud en el trabajo",
      icon: health_svg,
      link: "/descargas/PoliticaDeSaludySeguridad.pdf",
    },
    {
      name: "Política de Calidad",
      icon: Quality_svg,
      link: "/descargas/PoliticaDeCalidad.pdf",
    },
    {
      name: "Política de alcohol y drogas",
      icon: drugs_svg,
      link: "/descargas/PoliticaDeAlcoholyDrogas.pdf",
    },
  ];
  return (
    <div className={`o-politics o-corportate--segment`}>
      <div className="m-slogan">
        <p className={"a-title a-title--body a-underline text-center"}>
          NUESTAS POLITICAS
        </p>
        <p className={"politics-text text-center"}>
          Esperamos que nos brinde su confianza para poder ofrecer la seguridad
          que usted merece.
        </p>
      </div>
      {features.length > 0 && <File items={features} />}
    </div>
  );
};

export default OurPolitics;
