import * as React from "react";
// Remember that all const are suffix _svg

const drugs_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="281.333"
    className={"drugs_svg"}
    height="281.333"
    version="1.0"
    viewBox="0 0 211 211"
  >
    <path d="M65.5 44.2c-.3.7-.4 24.7-.3 53.3l.3 52h81l.3-42.8.2-42.8-9.7-10.5-9.8-10.4H96.7c-23.7 0-30.9.3-31.2 1.2zm70.4 10.7 9.1 9.9-.2 41.4-.3 41.3h-77l-.3-50c-.1-27.5 0-50.6.3-51.3.3-.9 7.2-1.2 29.8-1.2h29.4l9.2 9.9z" />
    <path d="M124 59v7h7c4 0 7-.4 7-1s-2.7-1-6-1h-6v-6c0-3.3-.4-6-1-6s-1 3-1 7zM77.7 80.6c-1.9 2 2.7 2.4 25.4 2.4 21.5 0 24.9-.2 24.9-1.5s-3.4-1.5-24.8-1.5c-13.7 0-25.2.3-25.5.6zM77.7 93.6c-1.9 2 2.6 2.4 23.9 2.4 20.1 0 23.4-.2 23.4-1.5s-3.3-1.5-23.3-1.5c-12.9 0-23.7.3-24 .6zM78.5 106.1c-1.5 2.3 1.6 3 13.1 2.7 9.8-.2 11.9-.6 11.9-1.8 0-1.2-2.1-1.5-12.2-1.8-8.1-.2-12.4.1-12.8.9zM121.6 115.2c-.8 3-.8 8.2 0 10.3.4.8 1.9 2.2 3.5 3.1 3.9 2 4.1 6.4.4 6.4-1.4 0-2.5.4-2.5 1 0 .5 2.5 1 5.5 1s5.5-.5 5.5-1c0-.6-1.1-1-2.5-1-3.7 0-3.5-4.4.4-6.4 3.2-1.7 4.1-3.4 4.1-7.9 0-7.1-.6-7.7-7.5-7.7-5.7 0-6.3.2-6.9 2.2zm12.8 1c1.8 6.9-.6 11.8-5.9 11.8-5.3 0-7.7-4.9-5.9-11.8.6-1.9 1.3-2.2 5.9-2.2 4.6 0 5.3.3 5.9 2.2z" />
    <path d="M127.5 118.7c-3.5.5-4.5 1.7-3.8 4.4 1.2 4.4 7.7 4.8 9.2.6 1-2.6.3-5.8-1.3-5.6-.6.1-2.4.4-4.1.6zM112.3 123.2c-1.8 1.1-3.3 2.4-3.3 2.9 0 1.6 1.5 1 5-2.1 4-3.3 3.1-3.8-1.7-.8zM111.6 130.4c-2.9 2.4-3.1 5.6-.4 5.6s8-4.1 7.4-5.6c-.3-.9-.6-1.8-.6-2 0-1.2-3.9 0-6.4 2zm6.2.3c.5 1.8-2.8 2.9-3.8 1.2-.5-.7-.6-1.7-.3-2.2.7-1.2 3.5-.5 4.1 1zM83.4 167.5c-.3.8-.3 1.9.1 2.5.8 1.3 21.1 15 22.2 15 2-.2 21.8-14.7 22.1-16.3.7-3.7-2.9-2.7-11.9 3.4-5.1 3.4-9.8 6.2-10.4 6.2-.6 0-5.2-2.7-10.3-6.1-9.7-6.6-10.9-7.1-11.8-4.7z" />
  </svg>
);
const Quality_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={"Quality_svg"}
    width="281.333"
    height="281.333"
    version="1.0"
    viewBox="0 0 211 211"
  >
    <path d="M65.5 44.2c-.3.7-.4 24.5-.3 52.8l.3 51.5 39.4.3c30.9.2 39.6-.1 40.3-1 .4-.7.8-19.9.8-42.5V64l-9.6-10.5-9.6-10.5H96.4c-23.5 0-30.6.3-30.9 1.2zm69.7 10.6 8.8 9.7-.3 41-.2 41-38.2.3-38.3.2V45l29.8.1 29.7.1 8.7 9.6z" />
    <path d="m123.2 58.7.3 6.8 6.8.3c4.2.2 6.7-.1 6.7-.8 0-.6-2.5-1-6-1h-6v-6c0-3.5-.4-6-1-6-.7 0-1 2.5-.8 6.7zM77.7 79.6c-2 2.1 1.4 2.4 24.4 2.4 19.6 0 25-.3 24.7-1.3-.4-.9-6.3-1.3-24.4-1.5-13.2-.1-24.3.1-24.7.4zM77.7 92.6c-2 2.1 1.3 2.4 22.9 2.4 18.4 0 23.5-.3 23.2-1.3-.4-.9-6-1.3-22.9-1.5-12.4-.1-22.8.1-23.2.4zM77.2 106.2c.3.9 3.8 1.4 11.4 1.6 11.4.3 15.4-.2 15.4-1.8 0-.6-5.4-1-13.6-1-10.4 0-13.5.3-13.2 1.2zM118.5 112.4c-5.4 2.3-6.9 8.7-3.5 14.4 1.1 1.8 2 4.9 2 6.8 0 3.1.3 3.5 1.8 2.8 2.1-.9 4.9-.9 8 .1 2 .7 2.2.4 2.2-3.6 0-2.4.7-5.3 1.5-6.3.8-1.1 1.5-3.4 1.4-5l-.1-3.1-.8 3.4c-1.2 4.9-4.2 7.5-8.7 7.4-6.4-.2-10.2-6.8-7.2-12.5 1.6-3 7.2-5 10.5-3.8 1.5.6 2.9.9 3.1.6.4-.4-5.4-2.6-6.6-2.6-.3.1-2 .7-3.6 1.4zm9.5 20.4c0 2.1-.3 2.3-2.9 1.7-1.7-.3-3.9-.1-5 .5-1.9 1-2.1.8-2.1-1.8 0-2.7.2-2.8 5-2.8 4.6 0 5 .2 5 2.4z" />
    <path d="m126.5 118-3.9 4-2.3-2.2c-3.5-3.3-4.1-1.2-.6 2.3l3.1 3.1 4.1-3.8c4.9-4.4 6.9-7.4 4.8-7.4-.7 0-3 1.8-5.2 4zM83.4 167.5c-.3.8-.3 1.9.1 2.5.8 1.3 21.1 15 22.2 15 2-.2 21.8-14.7 22.1-16.3.7-3.8-2.9-2.7-12.4 3.8l-9.7 6.6-9.9-6.6c-5.4-3.6-10.3-6.5-10.8-6.5-.6 0-1.3.7-1.6 1.5z" />
  </svg>
);
const health_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="281.333"
    height="281.333"
    version="1.0"
    viewBox="0 0 211 211"
    className={"health_svg"}
  >
    <path d="M65.5 44.2c-.3.7-.4 24.5-.3 52.8l.3 51.5 39.4.3c30.9.2 39.6-.1 40.3-1 .4-.7.8-19.9.8-42.5V64l-9.6-10.5-9.6-10.5H96.4c-23.5 0-30.6.3-30.9 1.2zm69.7 10.6 8.8 9.7-.3 41-.2 41-38.2.3-38.3.2V45l29.8.1 29.7.1 8.7 9.6z" />
    <path d="m123.2 58.7.3 6.8 6.8.3c4.2.2 6.7-.1 6.7-.8 0-.6-2.5-1-6-1h-6v-6c0-3.5-.4-6-1-6-.7 0-1 2.5-.8 6.7zM77.7 79.6c-2 2.1 1.4 2.4 24.4 2.4 19.6 0 25-.3 24.7-1.3-.4-.9-6.3-1.3-24.4-1.5-13.2-.1-24.3.1-24.7.4zM77.7 92.6c-2 2.1 1.3 2.4 22.9 2.4 18.4 0 23.5-.3 23.2-1.3-.4-.9-6-1.3-22.9-1.5-12.4-.1-22.8.1-23.2.4zM77.2 106.2c.3.9 3.8 1.4 11.4 1.6 11.4.3 15.4-.2 15.4-1.8 0-.6-5.4-1-13.6-1-10.4 0-13.5.3-13.2 1.2zM119.8 108.8c-2.2 2.3-4.1 7.2-2.8 7.2.6 0 1 1 1 2.3 0 2.9 1.8 4.7 4.5 4.7 5.7 0 7.9-9.2 3.2-13.5-3.1-2.9-3.6-3-5.9-.7zm2.9 2.4c-.3.7-.5.2-.5-1.2s.2-1.9.5-1.3c.2.7.2 1.9 0 2.5zm-.1 1.8c.8 0 1.4-.7 1.4-1.6 0-1.4.3-1.4 1.5-.4 2.9 2.4 1.8 4-3 4-4.6 0-5.8-1.3-3.2-3.9 1-1 1.3-.9 1.5.3.2.9 1 1.6 1.8 1.6zm4.4 4.3c0 .7-.6 2.1-1.3 3.2-2.1 2.7-6.7 1.3-6.7-2 0-2.2.4-2.5 4-2.5 2.6 0 4 .4 4 1.3zM117 125.4c-2.2.7-3.6 1.8-3.8 3.1-.3 1.9-.3 1.9 1.2-.1 1.8-2.5 6-3.6 7.7-2 .8.8 1.3.8 1.6-.1.6-1.6 6.1.1 8 2.4 1.3 1.7 1.3 1.6.3-.4-.5-1.2-1.4-2.4-1.9-2.8-1.9-1.1-9.7-1.2-13.1-.1z" />
    <path d="M117.3 130.6c-.9 2.5.3 5.7 3 7.7l2.7 2.1 2.8-2.1c2.6-1.9 4.5-8.1 2.5-8.4-5.1-.8-10.5-.5-11 .7zm4.5.6c.8.8 1.5.8 2.7-.2 2.1-1.8 4 .1 3.2 3.2-.9 3.4-4.2 5.2-6.7 3.6-2.2-1.4-3.6-5-2.5-6.7.8-1.4 1.8-1.4 3.3.1zM83.4 167.5c-.3.8-.3 1.9.1 2.5.8 1.3 21.1 15 22.2 15 2-.2 21.8-14.7 22.1-16.3.7-3.8-2.9-2.7-12.4 3.8l-9.7 6.6-9.9-6.6c-5.4-3.6-10.3-6.5-10.8-6.5-.6 0-1.3.7-1.6 1.5z" />
  </svg>
);
export { drugs_svg, health_svg, Quality_svg };
