import "./file.scss";

import SanityImage from "gatsby-plugin-sanity-image";
import React from "react";

export type File = { name: string; icon: React.ReactNode; link: string };
const Cards: React.FC<any> = (props: { items: File[] }) => {
  const items: File[] = [];

  props.items.forEach((item, index) => {
    items.push(
      <a href={`${item.link}`} target={"_blank"} rel="noreferrer">
        <div className={"a-item--cards"} key={index}>
          <div className={"a-item-cards__icon"}>{item.icon}</div>
          <p className={"a-title a-title--cards "}>{item.name}</p>
        </div>
      </a>
    );
  });

  return <div className={"m-cards grid-x3 grid-x3--break-tablet"}>{items}</div>;
};

export default Cards;
