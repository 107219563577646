import "./action-ratio.scss";

import { Link } from "gatsby";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import ActionMap from "./map/map";

const ActionRatio: React.FC = () => {
  const { t } = useTranslation("global");

  return (
    <div className={"o-action-ratio"}>
      <div className={"c-action-ratio"}>
        <section className={"action-ratio__text"}>
          <h2
            className={"a-title a-title--action"}
            // data-sal="slide-down"
            // data-sal-duration="800"
            // data-sal-easing="ease"
          >
            <Trans t={t}>action-ratio.title</Trans>
          </h2>
          <Trans t={t} components={{ Link: <Link />, bold: <strong /> }}>
            action-ratio.desc
          </Trans>
        </section>
        <section className={"action-ratio__map"}>
          <ActionMap />
        </section>
      </div>
    </div>
  );
};

export default ActionRatio;
