import "./submit.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

import { close_svg } from "../../../Modals/elements_modal";
import { StateForm } from "../../useForm";
import { check_svg } from "../elements_form";

export type SubmitType = {
  stateForm: StateForm;
  textButtonSubmit: string;
  textButtonSubmitting: string;
  textInfo?: string;
};

type PropsSubmit = PropsWithChildren<{ submit: SubmitType }>;

const Submit: React.FC<PropsSubmit> = (props: PropsSubmit) => {
  return (
    <div className={"m-submit"}>
      <button
        type="submit"
        disabled={props.submit.stateForm > 0}
        className={`m-button 
        ${
          props.submit.stateForm === StateForm.SUCCESS
            ? "m-button--success"
            : ""
        }
        ${
          props.submit.stateForm === StateForm.SUBMITTING
            ? "m-button--submitting"
            : ""
        }
        ${props.submit.stateForm === StateForm.FAIL ? "m-button--fail" : ""}
        `}
      >
        {props.submit.stateForm === StateForm.REST && (
          <> {props.submit.textButtonSubmit}</>
        )}
        {props.submit.stateForm === StateForm.SUBMITTING && (
          <> {props.submit.textButtonSubmitting} </>
        )}
        {props.submit.stateForm === StateForm.SUCCESS && (
          <> Completado {check_svg} </>
        )}
        {props.submit.stateForm === StateForm.FAIL && <> Error {close_svg} </>}
      </button>
      {props.submit.stateForm === StateForm.FAIL && (
        <p className={"m-submit__sms m-submit__sms--fail"}>
          Ha ocurrido un <b>error</b>: {props.submit.textInfo}
        </p>
      )}
      {props.submit.stateForm === StateForm.SUCCESS && (
        <p className={"m-submit__sms m-submit__sms--success"}>
          Formulario <b>enviado </b> con éxito: {props.submit.textInfo}
        </p>
      )}
    </div>
  );
};

export default Submit;
